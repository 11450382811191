<template>
  <div>

    <van-nav-bar title="数据统计" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right></template>
    </van-nav-bar>

    <div style="overflow: hidden;background: #fff;padding-top:48px">
      <van-row class="biaotou" style="margin-top: 6px;">
        <van-col span="3">#</van-col>
        <van-col span="7">销售员</van-col>
        <van-col span="7">总销量</van-col>
        <van-col span="7">已核销</van-col>

      </van-row>
      <van-row class="biaotou2" v-for="(items,idx) in formlist" :key="idx">
        <van-col span="3">{{idx+1}}</van-col>
        <van-col span="7">{{items.sname}}</van-col>
        <van-col span="7">{{items.total}}</van-col>
        <van-col span="7">{{items.canCounts}}</van-col>
      </van-row>

      <div class="he_shuju2">已购买客户</div>
      <van-row class="biaotou">
        <van-col span="2">#</van-col>
        <van-col span="5">车牌号码</van-col>
        <van-col span="6">联系方式</van-col>
        <van-col span="5">用户名</van-col>
        <van-col span="6">购买时间</van-col>

      </van-row>
      <van-row class="biaotou2" v-for="(item,idex) in list" :key="idex">
        <van-col span="2">{{idex+1}}</van-col>
        <van-col span="5">{{item.carNo}}</van-col>
        <van-col span="6">{{item.phone}}</van-col>
        <van-col span="5">{{item.name}}</van-col>
        <van-col span="6">{{item.createdDate?item.createdDate.substr(5,11):''}}</van-col>

      </van-row>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";

export default {
  data() {
    return {
      gid: "",
      imgs: "../../assets/img/tongji.png",
      mcId: this.$route.query.mcId,
      uid: "",
      sjlist: [],
      listname: "",
      gids: "",
      page: 0,
      size: 100,
      sid: "-1",
      list: [],
      formlist: [],
    };
  },
  methods: {},
  created() {
    this.namelist();
    this.yewulist();
  },
  methods: {
    namelist() {
      userModel
        .combouse({
          mcId: this.mcId,
          sid: this.sid,
          page: this.page,
          size: this.size,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.list = res.data.content;
          }
        });
    },
    yewulist() {
      userModel.combosell({ mcId: this.mcId, sid: this.sid }).then((res) => {
        if (res.code == 200) {
          this.formlist = res.data;
        }
      });
    },
    //商家list
    todaiban(item) {
      this.$router.push({
        path: "commission_xq",
        query: {
          aid: sessionStorage.getItem("aid"),
          carNo: item.carNo,
        },
      });
    },
    listtongji() {
      userModel.ersale({ aid: this.aid, gids: this.gids }).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },

    xuanzhe(item) {
      console.log(item);
      item.isOk = !item.isOk;
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    toxinxi() {
      this.$router.push({
        path: "statistics",
        query: {
          aid: this.aid,
        },
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style  lang="less" scoped>
.che_h2 {
  width: 50%;
}
.heji_h1 {
  height: 25px;
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  background: #fff;
}
.hj_a {
  float: left;
  width: 33%;
  text-align: center;
}
.shenqin {
  border-top: 1px solid #f5f5f5;
  font-size: 14px;
  color: #666;
  line-height: 20px;
  padding-top: 8px;
}

.c_h1 {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 2px;
  width: 50%;
  height: 25px;
  margin-left: 55px;
}
.van-dropdown-menu {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background: #fff;
}
.h1 {
  height: 45px;
  border-bottom: 1px solid #ccc;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 99;
  line-height: 45px;
}
.van-dropdown-menu__title--active {
  color: #07c160;
}
.xuanzheqi {
  border: 1px solid #dcdfe6;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border-radius: 4px;
  width: 95%;
}

.mendianh {
  width: 30%;
  overflow: hidden;
}

.xiabiao {
  position: absolute;
  right: 0;
  top: 0;
  color: #666;
  margin-top: 15px;
  margin-right: 5px;
}
.topp {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
.biaoti {
  padding: 10px 12px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
}

.red {
  color: red;
  font-weight: bold;
  z-index: 999;
}

.shuju {
  padding: 15px 12px;
  font-size: 14px;
}

.van-col {
  text-align: center;
  border-right: 1px solid #f1f1f1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 0 0px;
}
.imgs {
  width: 24px;
  margin-top: 11px;
}
.biaotou {
  border: 1px solid #f1f1f1;
  font-size: 14px;
  line-height: 30px;
  color: #000;
}

.biaotou2 {
  font-size: 14px;
  line-height: 30px;
  border: 1px solid #f1f1f1;
  border-top: none;
  color: #666;
}
.let {
  width: 40px;
  height: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
.rit {
  width: 40px;
  height: 100%;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}
.he_shuju {
  margin-top: 15px;
  border-top: 1px solid #ccc;
  line-height: 40px;
  overflow: hidden;
  padding: 0 12px;
  font-size: 14px;
  font-weight: bold;
}

.he_shuju2 {
  margin-top: 15px;
  border-top: 1px solid #ccc;
  line-height: 40px;
  overflow: hidden;
  padding: 0 12px;
  font-size: 14px;
  text-align: center;
  color: #000;
  font-weight: bold;
}
</style>
